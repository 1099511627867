import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import('../views/UploadView.vue')
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../views/ListView.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/SearchView.vue')
  },
  {
    path: '/event',
    name: 'event',
    component: () => import('../views/EventView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: { hideMenu: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // Assuming the token is stored in localStorage with the key 'authToken'
  const isAuthenticated = localStorage.getItem('authToken');

  if (!isAuthenticated && to.path !== '/login') {
    // Redirect to the login page if not authenticated and trying to access a restricted page
    next('/login');
  } else {
    // Proceed to the route
    next();
  }
});

export default router
