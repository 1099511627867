<template>
  <nav class="app-nav-bar" v-if="!$route.meta.hideMenu">
    <router-link to="/"
      ><img id="home-link-logo" alt="Weaverize Logo" src="./assets/images/identipic.svg"
    /></router-link>
    <div>
      <router-link class="nav-link" to="/upload">Reconnaissance de personnes</router-link>
      <router-link class="nav-link" to="/list">Récupération d'images</router-link>
      <router-link class="nav-link" to="/event">Gestion d'événements</router-link>
    </div>
  </nav>
  <router-view />
</template>

<style scoped>
#home-link-logo {
  height: 28px;
  padding:0 12px;
  transition: scale 0.2s ease;
}
#home-link-logo:hover{
  scale:1.1;
}
.app-nav-bar{
  height:70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-link{
  padding:0 10px;
  text-decoration: none;
  font-family: 'PoppinsLight', sans-serif;
  font-size: 1rem;
  color:var(--lightblue);
  transition: color 0.2s ease;
}
.nav-link:hover{
  color:var(--orange);
}
.nav-link.router-link-exact-active {
  font-family: 'PoppinsLight', sans-serif;
}
</style>
